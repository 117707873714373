<template>
  <MLayer :open="isDisplayProductDetails" :closeOnOverlay="true" :layerTitle="$t('ProductDetailTitle')"
    @update:open="isLayerClosed">
    <template v-slot:default>
      <div v-if="productDetails" class="product_details">
        <div class="product_info">
          <div class="product_info__infos">
            <MHeading :underline="true" size="s">
              <template v-slot:default>{{ productDetails.name }}</template>
            </MHeading>
            <div class="product_info__infos__carac">
              {{ $t("GTIN") }} {{ productDetails.gtins }}
            </div>
            <div class="product_info__infos__carac">
              {{ $t("ADEOKEY") }} {{ productDetails.adeoKey }}
            </div>
            <div class="product_info__infos__carac">
              {{ $t("REFERENCE") }} {{ productDetails.references }}
            </div>
          </div>
          <div class="product_info__image">
            <img :src="getImagePath()" :alt="productDetails.name" />
          </div>
        </div>

        <div v-if="productDetails.files" class="product_files">
          <MDataTableV2 :items="productDetails.files" selectable v-model:selection="selection" @select-row="selectFile"
            @unselect-row="unselectFile" @select-all-rows="selectAllFiles" @unselect-all-rows="unselectAllFiles">
            <MDataTableColumn :label="$t('type')" value="documentTypeLabel"></MDataTableColumn>
            <MDataTableColumn :label="$t('fileName')" value="fileNameToDisplay"></MDataTableColumn>
            <MDataTableColumn :label="$t('extension')" value="fileExtension"></MDataTableColumn>
          </MDataTableV2>
        </div>
        <div v-else>{{ $t("noFilesMessage") }}</div>
        <div class="download_button">
          <MButton :label="$t('DownloadFiles')" @click="downloadFiles"></MButton>
        </div>
      </div>
    </template>
  </MLayer>
</template>

<script>
import {
  MLayer,
  MButton,
  MHeading,
  MDataTableV2,
  MDataTableColumn,
} from "@mozaic-ds/vue-3";
import PhotoService from "@/service/PhotoService.js";

export default {
  name: "ProductDetails",

  props: ["productDetails", "isDisplayProductDetails"],

  components: {
    MLayer,
    MButton,
    MHeading,
    MDataTableV2,
    MDataTableColumn,
  },

  methods: {
    getImagePath() {
      return this.photoService.getImagePath(this.productDetails.photoUrl);
    },

    isLayerClosed() {
      this.filesToDownload = [];
      this.selection = [];
      this.$emit("layer-closed");
    },

    selectFile(event) {
      this.filesToDownload.push(event.data);
    },

    unselectFile(event) {
      this.filesToDownload = this.filesToDownload.filter(
        (file) => file.id !== event.data.id
      );
    },

    selectAllFiles(event) {
      event.data.forEach((file) => {
        this.addFileToDownLoad(file);
      });
    },

    unselectAllFiles() {
      this.filesToDownload = [];
    },

    addFileToDownLoad(file) {
      this.filesToDownload.push(file);
    },

    downloadFiles() {
      this.$emit("download-files", this.filesToDownload);
    },
  },

  data() {
    return {
      photoService: new PhotoService(),
      filesToDownload: [],
      selection: [],
      open: this.isDisplayProductDetails,
    };
  },

  emits: ["layer-closed", "download-files"],
};
</script>

<style lang="scss">
.mc-layer {
  &__dialog {
    max-width: 688px !important;
  }
}

.product_details {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}

.product_info {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__infos {
    text-align: start;

    &__carac {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
  }

  &__image {
    background-color: #ffffff;
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 200px;
      max-height: 200px;
    }
  }
}

.product_files {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.download_button {
  margin-top: 2rem;
}
</style>
