<template>
    <div class="banner">
        <div class="banner__content">
            <div class="banner__content__main-logos">
                <div class="banner__content__main-logos__adeo-logo">
                    <AdeoLogo />
                </div>
                <div class="banner__content__main-logos__title">{{ $t("documents") }}</div>
            </div>
            <div class="banner__content__small-logos">
                <div class="banner__content__small-logos__logo" v-for="logo in logos">
                    <img :src="getLogoImagePath(logo)" :alt="logo" />
                </div>
            </div>
        </div>
        <div class="select-language-container">
            <LanguageSelector :langs="langs" :currentLang="getCurrentLang()" @change-lang="changeLanguage" />
        </div>
    </div>
</template>

<script>
import AdeoLogo from "@/components/AdeoLogo.vue";
import LanguageSelector from "@/components/LanguageSelector.vue";
import PhotoService from "@/service/PhotoService";
import i18next from "i18next";

export default {
    name: "Banner",
    components: {
        AdeoLogo,
        LanguageSelector,
    },
    methods: {
        getLogoImagePath(name) {
            return this.photoService.getLogoImagePath(name);
        },
        changeLanguage(code) {
            i18next.changeLanguage(code);
        },
        getCurrentLang() {
            return this.langs.filter((lang) => lang.code === i18next.resolvedLanguage)[0];
        },
    },
    data() {
        return {
            photoService: new PhotoService(),
            logos: [
                "AliceDelice",
                "BricoCenter",
                "Bricoman",
                "Kbane",
                "LeroyMerlin",
                "Quotatis",
                "Weldom",
                "Zodio",
            ],
            langs: [
                { id: 1, value: 1, label: "Français", src: "frenchFlag", code: "fr" },
                { id: 2, value: 2, label: "English", src: "englishFlag", code: "en" },
                { id: 3, value: 3, label: "Español", src: "spanishFlag", code: "es" },
                { id: 4, value: 4, label: "Português", src: "portugueseFlag", code: "pt" },
                { id: 5, value: 5, label: "Polski", src: "polishFlag", code: "pl" },
                { id: 6, value: 6, label: "Română", src: "romanianFlag", code: "ro" },
                { id: 7, value: 7, label: "українська", src: "ukrainianFlag", code: "uk" },
                { id: 8, value: 8, label: "Ελληνικά", src: "greekFlag", code: "el" },
                { id: 9, value: 9, label: "Italiano", src: "italianFlag", code: "it" },
            ],
        };
    },
};
</script>

<style lang="scss">
.banner {
    display: flex;
    justify-content: space-around;
    align-items: center;

    &__content {
        display: flex;
        flex-direction: row;
        max-width: 1008px;
        height: 100px;

        &__main-logos {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            margin-right: 20px;

            &__title {
                color: #a6a696;
                text-transform: uppercase;
                font-size: 13px;
                font-weight: 500;
                line-height: 18px;
                text-align: left;
                margin: 5px;
            }
        }

        &__small-logos {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;

            &__logo {
                margin-right: 5px;
                margin-left: 5px;

                img {
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }
}
</style>
