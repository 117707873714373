<template>
  <ProductDetails :productDetails="productDetails" :isDisplayProductDetails="isDisplayProductDetails"
    @layer-closed="isLayerClosed" @download-files="getFiles">
  </ProductDetails>

  <Body @send-search-string="sendNewSearch" @display-product-details="displayProductDetails" @update-page="updatePage"
    :productsPage="productsPage" :displaySearchResult="displaySearchResult" :isSearchConclusive="isSearchConclusive"
    :productsPerPage="productsPerPage" :isLoading="isLoading">
  </Body>
</template>

<script>
import Body from "@/components/Body.vue";
import ProductService from "@/service/ProductService.js";
import FileService from "@/service/FileService";
import PhotoService from "@/service/PhotoService";
import ProductDetails from "@/components/ProductDetails.vue";
import { t } from "i18next";

export default {
  name: "Main",

  components: {
    Body,
    ProductDetails,
  },

  methods: {
    async updatePage(value) {
      await this.fetchNewSearchPage(value, this.productsPerPage);
    },

    async sendNewSearch(searchString) {
      this.searchString = searchString;
      await this.fetchNewSearchPage(1, this.productsPerPage);
    },

    async fetchNewSearchPage(from, size) {
      this.productsPage = { "content": [], "totalElements": 0, "totalPages": 0, "size": 0, "number": 0, "pageable": { "pageNumber": 0 } };
      this.displaySearchResult = false;
      this.isSearchConclusive = false;
      this.isLoading = true;

      if (this.searchString !== "") {
        try {
          await this.searchProducts(from, size);
        } catch (e) {
          this.message = 'No result matching "' + this.searchString + '"';
          console.log(this.message + ": " + e);
        }
      }
    },

    async searchProducts(from, size) {
      return await this.productService
        .getProductsPageWithPaginationParams(this.searchString, from, size)
        .then(response => response.json())
        .then(json => {
          this.isLoading = false
          this.displaySearchResult = true
          if (json.content.length > 0) {
            this.productsPage = json
            this.isSearchConclusive = true
          }
        });
    },

    async displayProductDetails(id) {
      this.isDisplayProductDetails = true;
      await this.getProductDetails(id)
    },

    async getProductDetails(id) {
      this.productDetails = this.productsPage.content.find(product => product.id == id)
      try {
        await this.productService
          .getProductDetails(this.productDetails.adeoKey, this.productDetails.gtins)
          .then(response => response.json())
          .then(json => {
            if (json.files.length > 0) {
              const files = json.files
              this.productDetails.files = files.map(file => {
                return {
                  ...file,
                  "fileExtension": this.getExtension(file.fileName),
                  "documentTypeLabel": this.getTranslatedDocumentTypeLabel(file.documentType),
                  "fileNameToDisplay": this.getProperFileName(file, files)
                }
              })
            } else {
              this.productDetails.files = null;
            }
          })
      } catch (error) {
        this.productDetails.files = null;
      }
    },

    stripFileNameFromExtension(fileName) {
      const split = fileName.split('.');
      split.pop();
      return split.join();
    },

    getExtension(fileName) {
      const split = fileName.split('.');
      return split[split.length - 1];
    },

    getProperFileName(file, files) {
      return `${this.getTranslatedDocumentTypeLabel(file.documentType)}-${this.productDetails.gtins}-${this.productDetails.adeoKey}-${this.getFileNumber(file, files)}`;
    },

    getFileNumber(file, files) {
      const number = files
        .filter(f => this.getTranslatedDocumentTypeLabel(f.documentType) === this.getTranslatedDocumentTypeLabel(file.documentType))
        .map((f, index) => { return { ...f, "index": index } })
        .filter(f => f.id === file.id)
        .map(f => f.index)
      return parseInt(number) + 1
    },

    isLayerClosed() {
      this.isDisplayProductDetails = false;
    },

    async getFiles(filesToDownload) {
      if (filesToDownload.length === 1) {
        await this.downloadFile(filesToDownload[0])
      } else if (filesToDownload.length > 1) {
        const filesIds = filesToDownload.map(file => file.id).join()
        await this.downloadFiles(filesIds);
      }
    },

    async downloadFile(file) {
      await this.fileService
        .downloadFile(file.id)
        .then(response => response.blob())
        .then(data => {
          const link = document.createElement('a')
          link.href = URL.createObjectURL(data)
          link.download = `${file.fileNameToDisplay}.${file.fileExtension}`
          link.click()
          URL.revokeObjectURL(link.href)
        })
    },

    async downloadFiles(filesIds) {
      await this.fileService
        .downloadZipFile(filesIds)
        .then(response => response.blob())
        .then(data => {
          const link = document.createElement('a')
          link.href = URL.createObjectURL(data)
          link.download = "zip_documents.zip"
          link.click()
          URL.revokeObjectURL(link.href)
        })
    },
    getTranslatedDocumentTypeLabel(documentType) {
      switch (documentType) {
        case 'DOC':
          return t('documentTypeLabel.DOC')
        case 'IMD':
          return t('documentTypeLabel.IMD')
        case 'EPS':
          return t('documentTypeLabel.EPS')
        case 'IMS':
          return t('documentTypeLabel.IMS')
        case 'DOP':
          return t('documentTypeLabel.DOP')
        case 'ERP':
          return t('documentTypeLabel.ERP')
        case 'PAC':
          return t('documentTypeLabel.PAC')
        case 'PLB':
          return t('documentTypeLabel.PLB')
        case 'FDS':
          return t('documentTypeLabel.FDS')
        default:
          return t('documentTypeLabel.default')

      }
    },
  },

  data() {
    return {
      productService: new ProductService(),
      fileService: new FileService(),
      photoService: new PhotoService(),
      productsPage: {},
      displaySearchResult: false,
      isSearchConclusive: false,
      isDisplayProductDetails: false,
      productDetails: null,
      isLoading: false,
      searchString: "",
      productsPerPage: 16
    };
  },
};
</script>
