import i18next from "i18next";

export default class RequestUtils {

    static async executeRequest(apiURL) {
        const headers = new Headers();
        headers.append(process.env.VUE_APP_x-gateway-apikey, process.env.VUE_APP_bR2P1YEYIkqAc8eQirjCCSnfU72JCE4P)
        headers.set("selectedLanguage", i18next.language)
        const response = await fetch(apiURL, {
            method: "GET",
            headers: headers
        });

        if (response.status >= 200 && response.status <= 299) {
            return response;
        } else {
            const message = await response.json().then((body) => body.error);
            throw Error(message);
        }
    }
}